import React, { useEffect, useState } from 'react';
import {Bounce, Slide, toast} from 'react-toastify';
import {useData} from "./DataProvider";
const WebSocketComponent = () => {
    const {loadOrders} = useData();
    const [inputValue, setInputValue] = useState('');
    const [socket, setSocket] = useState(null);

    const instructionProgress = 1;
    const instructionReloadOrders = 2;

    //TODO REMOVE CHECK WHEN STRICT MODE IS OFF
    let check = false;

    useEffect(() => {
        if(check) return;
        check = true;
        connectWebSocket();
        return () => {
            // Clean up WebSocket connection when component unmounts
            if (socket) {
                socket.close();
            }
        };
    }, []);

    const connectWebSocket = () => {
        const ws = new WebSocket('wss://dispatchapi.rotaoptimizasyonu.com/notification/');

        ws.onopen = () => {
            console.log('WebSocket connected');
        };

        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
            showToast(message);
        };

        ws.onclose = () => {
            console.log('WebSocket disconnected');
            setSocket(null); // Reset socket state
            setTimeout(connectWebSocket, 3000); // Reconnect after 3 seconds
        };

        setSocket(ws);
    };

    const showToast = (message) => {
        //Try parsing Json'''
        let toastInstruction = null;
        try {
            toastInstruction = JSON.parse(message);
        }catch{
            return;
        }

        if(toastInstruction.type===instructionProgress){
            //if not active create, else update
            if(!toast.isActive(toastInstruction.id)){
                toast.loading(toastInstruction.message, {
                    toastId: toastInstruction.id,
                    position: "bottom-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                    closeButton: false,
                });
            }else{
                toast.update(toastInstruction.id, {
                    render: toastInstruction.message,
                    position: "bottom-center",
                    type: toastInstruction.isLoading? 'loading': toastInstruction.success?'success':'error',
                    autoClose: true,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    isLoading: toastInstruction.isLoading,
                    transition: Slide,
                    closeButton: !toastInstruction.isLoading,
                });
            }
        }else if (toastInstruction.type===instructionReloadOrders){
            loadOrders();
        }


    }

    // const sendMessage = () => {
    //     if (socket && inputValue.trim() !== '') {
    //         socket.send(JSON.stringify({ message: inputValue }));
    //         setInputValue('');
    //     }
    // };

    return null;
};

export default WebSocketComponent;

