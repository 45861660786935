import React from 'react';
import './RedCircleIcon.css'; // Import the CSS file for styling

const RedCircleIcon = ({show}) => {
    return (
        <div className="red-circle-icon-holder">
            {show? (
                <div className="red-circle-icon">
                    <span>!</span>
                </div>
            ):
                null
            }
        </div>
    );
}

export default RedCircleIcon;
