import './customers.css';
import {useMap} from "../../Components/MapProvider";
import {useEffect, useRef, useState} from "react";
import {useData} from "../../Components/DataProvider";
import InfiniteScroll from "react-infinite-scroll-component";
import RedCircleIcon from "../../Components/Icons/RedCircleIcon";
export const CustomerList = ({onItemFocus, newCustomerRequest, wide=true, addbutton = false}) =>{
    const {customers, loadCustomers} = useData();

    const scrollRef = useRef();

    const [nextPage, setNextPage] = useState(1);
    const CustomerListItem = ({item, onFocus}) =>{
        return (
            <div className='item' onClick={()=>{
                onFocus(item);
            }}>
                <div className='code'>{item.code}</div>
                <div className='name'>{item.name}</div>
                <RedCircleIcon show={item.locationOk==false} />
            </div>
        );
    }
    useEffect(()=>{
        getNext();
    }, [])
    const getNext = async () =>{
        var nextp = await loadCustomers(nextPage);
        setNextPage(nextp);
    };

    useEffect(()=>{
        console.log('NextPage', nextPage)
    }, [nextPage])
    return (
        <div className='customer-list-holder'>
            <div className='customer-list-content' style={wide?{width: '100%'}:null}>
                <div className='customer-scroll-holder'>
                    <div className='customer-list' id="scrollRef">
                    <InfiniteScroll
                        dataLength={customers.length}
                        next={getNext}
                        scrollableTarget={'scrollRef'}
                        hasMore={getNext} // Replace with a condition based on your data source
                        loader={<p>Loading...</p>}
                        endMessage={<p>No more data to load.</p>}
                    >
                        {
                            customers.map(item => {
                                return <CustomerListItem item={item} onFocus={(i)=>{onItemFocus(i)}} />
                            })

                        }
                    </InfiniteScroll>
                    </div>

                    {/*<div className='customer-list'>*/}
                    {/*    {*/}
                    {/*        customers.map(item => {*/}
                    {/*            return <CustomerListItem item={item} onFocus={(i)=>{onItemFocus(i)}} />*/}
                    {/*        })*/}

                    {/*    }*/}
                    {/*    <button className={'add-button'} onClick={()=>{getNext()}}>More</button>*/}
                    {/*</div>*/}
                </div>
                {addbutton?
                <div className={'new-customer'}>
                    <button className={'add-button'} onClick={()=>{newCustomerRequest()}}>Add</button>
                </div>:null
                }
            </div>

        </div>
    );
}

export const CustomerInfo = ({commitCustomer}) => {
    const {focusCustomer, setFocusCustomer} = useData();
    const [newTitle, setNewTitle]  = useState('');
    const [newAddress, setAddress]  = useState('');
    const [newCode, setCode]  = useState('');
    const [newCity, setCity]  = useState('');
    const [newLat, setLat]  = useState(null);
    const [newLon, setLon]  = useState(null);

    // This will update the title if we change focus area if exits.
    // Otherwise, will prepare input for the fresh data.
    useEffect(()=>{
        if(focusCustomer){
            setNewTitle(focusCustomer.name);
            setCode(focusCustomer.code);
            setAddress(focusCustomer.address);
            setCity(focusCustomer.city?focusCustomer.city:'');
            setLat(focusCustomer.location?.lat)
            setLon(focusCustomer.location?.lon)
        }
        else{
            setNewTitle('');
            setCode('');
            setAddress('');
            setCity('');
        }
    }, [focusCustomer])

    // This will control the input change.
    const titleInputChanged = (e) =>{
        let value = e.target.value;
        // Change title in focus area if not null, this will automatically update the newTitle using hook above.
        // Otherwise, change newTitle manually
        if(focusCustomer) setFocusCustomer(prev => ({...prev, name: value}));
        else setNewTitle(value);
    }

    const addressInputChanged = (e) =>{
        let value = e.target.value;
        // Change title in focus area if not null, this will automatically update the newTitle using hook above.
        // Otherwise, change newTitle manually
        if(focusCustomer) setFocusCustomer(prev => ({...prev, address: value}));
        else setAddress(value);
    }

    const codeInputChanged = (e) =>{
        let value = e.target.value;
        // Change title in focus area if not null, this will automatically update the newTitle using hook above.
        // Otherwise, change newTitle manually
        if(focusCustomer) setFocusCustomer(prev => ({...prev, code: value}));
        else setCode(value);
    }

    const cityInputChanged = (e) =>{
        let value = e.target.value;
        // Change title in focus area if not null, this will automatically update the newTitle using hook above.
        // Otherwise, change newTitle manually
        if(focusCustomer) setFocusCustomer(prev => ({...prev, city: value}));
        else setCity(value);
    }

    const latInputChanged = (e) =>{
        // let value = e.target.value;
        // try {
        //     value = parseFloat(value)
        //     if(focusCustomer) setFocusCustomer(prev => ({...prev, location: {...prev.location, lat:e}}));
        //     else setLat(value);
        // }
        // catch{
        //     setLat(value);
        // }
    }

    const lonInputChanged = (e) =>{
        // let value = e.target.value;
        // try {
        //     value = parseFloat(value)
        //     if(focusCustomer) setFocusCustomer(prev => ({...prev, location: {...prev.location, lat:e}}));
        //     else setLon(value);
        // }
        // catch{
        //     setLon(value);
        // }
    }
    
    return(
        <>
            {focusCustomer?
            <div className={'customer-info-holder'}>
                <div className='customer-info-content'>
                    <div className={'customer-scroll-holder'}>
                        <div className={'customer-info'}>
                            <input name={'customerTitle'}
                                   onInput={(e)=>{titleInputChanged(e)}}
                                   className={'customer-title'}
                                   type={'text'}
                                   value={newTitle}
                                   placeholder={'customer Name'}/>
                                <h4>Customer Code</h4>
                                    <input name={'customerCode'}
                                           onInput={(e)=>{codeInputChanged(e)}}
                                           className={'customer-title'}
                                           type={'text'}
                                           value={newCode}
                                           placeholder={'Code'}/>
                            <h4>Address</h4>
                            <input name={'customerCode'}
                                   onInput={(e)=>{addressInputChanged(e)}}
                                   className={'customer-title'}
                                   type={'text'}
                                   value={newAddress}
                                   placeholder={'Address'}/>
                            <h4>City</h4>
                            <input name={'customerCode'}
                                   onInput={(e)=>{cityInputChanged(e)}}
                                   className={'customer-title'}
                                   type={'text'}
                                   value={newCity}
                                   placeholder={'City'}/>

                            <h4>Location</h4>
                            <div className={'locationHolder'}>
                            <input name={'customerLocationLat'}
                                   onInput={(e)=>{latInputChanged(e)}}
                                   className={'customer-title'}
                                   type={'number'}
                                   value={newLat}
                                   placeholder={'City'}/>
                            <input name={'customerLocationLon'}
                                   onInput={(e)=>{lonInputChanged(e)}}
                                   className={'customer-title'}
                                   type={'number'}
                                   value={newLon}
                                   placeholder={'City'}/>
                            </div>
                        </div>
                    </div>
                    {focusCustomer?
                    <div className={'new-customer'}>
                        <button className={'add-button'} onClick={()=>{commitCustomer()}}>Save</button>
                    </div>:null
                    }
                </div>
            </div>: null
                }
        </>
    );
}
