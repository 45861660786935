import React, { useState } from 'react';
import './menue.css';
import ExpandableMenue from "./ExpandableMenue";
import {Link, useNavigate} from "react-router-dom";

export default function Menue() {
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);
    const goTo = (url) =>{
        navigate(url);
        setIsExpanded(false);
    }
    return (
        <div className="main-menue">
            <ExpandableMenue isExpanded={isExpanded} setIsExpanded={setIsExpanded}>
                <div className='main-menue-option-container'>
                    <div className='main-menue-option-container-item' onClick={()=> (goTo('/results'))}><label>Results</label></div>
                    <div className='main-menue-option-container-item' onClick={()=> (goTo('/orders'))}><label>Orders</label></div>
                    <div className='main-menue-option-container-item' onClick={()=> (goTo('/customers'))}><label>Customers</label></div>
                    <div className='main-menue-option-container-item' onClick={()=> (goTo('/vehicles'))}><label>Vehicles</label></div>
                    <div className='main-menue-option-container-item' onClick={()=> (goTo('/areas'))}><label>Areas</label></div>
                </div>
            </ExpandableMenue>
        </div>
    );
}
