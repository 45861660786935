import React, { useState } from 'react';
import './auth.css'
const AuthScreen = ({authentificate}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    return (
        <div className={'auth-holder'}>
            <h2>Login</h2>
            <form className='auth-form' onSubmit={(e)=>{
                e.preventDefault();
                authentificate(username, password);
            }}>
                <div className='auth-cell'>
                    <label htmlFor="username">Username:</label>
                </div>
                <div className='auth-cell'>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className='auth-cell'>
                    <label htmlFor="password">Password:</label>
                </div>
                        <div className='auth-cell'>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                {error && <div>{error}</div>}
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default AuthScreen;
