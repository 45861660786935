import Screen from "../../Components/Screen";
import './results.css'
import {useEffect, useState} from "react";
import {useData} from "../../Components/DataProvider";
import {ResultList, ResultListInfo} from "./ResultComponents";
const Results = () =>{
    const {
        clear,
        dispatchResults,
        loadDispatchResults,
        loadDispatchResult,
        dispatchResultsInfo, setDispatchResultsInfo
    } = useData();

    //since we won't save or change it, we can keep it within the page context
    // const [focusResult, setFocusResult] = useState(null)
    const [focusResultInfo, setFocusResultInfo] = useState(null)
    const [focusResult, setFocusResult] = useState(null)

    const onResultFocus = async (result) => {
        if(result) {
            setFocusResult(result)
            var resultinfo = await loadDispatchResult(result);
            console.log('focus_info',resultinfo);
            setFocusResultInfo(resultinfo);
            setDispatchResultsInfo(resultinfo);
        }
    }

    useEffect(()=>{
        clear();
        loadDispatchResults();
    }, [])


    return (
        <Screen layout={2} colLeft={<ResultListInfo focusResult = {focusResult} focusResultInfo={focusResultInfo} wide={true}/>}
                colRight={<ResultList onItemFocus={(area)=> {onResultFocus(area)}} newResultRequest = {()=> {}} />}
        />
    );
}

export default Results;
