import React, { useState } from 'react';

const FileUploadButton = ({uploadFile, children}) => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        uploadFile(file)
    };

    const handleButtonClick = () => {
        document.getElementById('fileInput').click();
    };

    return (
        <div>
            <input
                id="fileInput"
                type="file"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
            />
            <button className={'add-button'} onClick={handleButtonClick}>{children}</button>
            {/*{selectedFile && (*/}
            {/*    <div className={'add-button'}>*/}
            {/*        <p>Selected File: {selectedFile.name}</p>*/}
            {/*        /!* You can display additional file details here *!/*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
};

export default FileUploadButton;
