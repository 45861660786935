import {Circle, CircleMarker, FeatureGroup, MapContainer, Marker, Polygon, Popup, TileLayer} from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css'
import './Map.css'
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css'
import {createContext, useEffect, useRef, useState} from "react";
import L from 'leaflet';
import {useMap} from "./MapProvider";
import {useData} from "./DataProvider";
import {Fade} from "hamburger-react";

import markerImg from '../assets/marker.png'
import {DivIcon} from "leaflet/src/layer";

const AreaPolygon = ({positions, label, color, fillColor, fill}) => {
    const center = positions.length>0?L.polygon(positions).getBounds().getCenter():null;
    const text = positions.length>0?L.divIcon({html: label, className: 'area-label'}):null;

    return(
        <Polygon positions={positions} color={color} fillColor={fillColor} fill={fill}>
            {center ?<Marker b position={center} icon={text} />:null}
        </Polygon>
    );
}
const Map = () =>{
    const { polygons,
        setPolygons,
        editMode,
        toggleEditMode,
        focusPoint, setFocusPoint,
    } = useMap();

    const {areas,
        setAreas,
        //Focus Area
        focusArea,
        setFocusArea,
        updatePolygon,
        customers,
        focusRoute,
        focusCustomer,
        dispatchResultsInfo} = useData();

    const mapRef = useRef(null);
    const featureGroupRef = useRef();

    const [focusPolygon, setFocusPolygon] = useState(null)
    const [newPositions, setNewPositions] = useState([])

    const MapPlaceholder = () => {
        return (
            <p>
                Map of London.{' '}
                <noscript>You need to enable JavaScript to see this map.</noscript>
            </p>
        )
    }
    const handleEdited = (e) => {
        console.log("ID");
        const polygon = e.layers.toGeoJSON();
        console.log(polygon);
        const points = polygon.features[0].geometry.coordinates[0].map(coord => [coord[1], coord[0]]);
        setNewPositions(points);

    };

    const handleDeleted = (e) => {
        console.log('Feature edited:', e);
        setNewPositions([]);
    };

    const handleAdded = (e) => {
        const polygon = e.layers.toGeoJSON();
        const points = polygon.features[0].geometry.coordinates[0].map(coord => [coord[1], coord[0]]);
        setNewPositions(points);
    }

    useEffect(()=>{
        if(!focusArea) return;
        console.log('NP', focusArea);
        updatePolygon(newPositions);
        // const featureGroup = featureGroupRef.current;
        // if(featureGroup) featureGroup.clearAllEventListeners();
        toggleEditMode();
    },[newPositions]);

    useEffect(()=>{
        const featureGroup = featureGroupRef.current;

        if (featureGroup && editMode===true) {
            // Add a polygon to the FeatureGroup
            featureGroup.clearLayers();
            const polygonCoords = focusArea.polygon.length>0?focusArea.polygon:[mapRef.current.getCenter()];
            const polygon = L.polygon(polygonCoords);
            featureGroup.addLayer(polygon);
            polygon.editing.enable();
            setFocusPolygon(polygon);
        }else if (editMode===false && focusPolygon){
            focusPolygon.editing.disable();
            var newPos = focusPolygon._latlngs[0].map(latlng => [latlng.lat, latlng.lng]);
            updatePolygon(newPos);
            featureGroup.clearLayers();
        }

    }, [editMode])


    function FocusMap({ lat, lng }) {
        const map = mapRef.current;
        map.setView([lat, lng], 13);
        return null;
    }

    useEffect(()=>{
        console.log(focusPoint);
        if(!focusPoint) return;
        const map = mapRef.current;
        if(!map) return;
        map.setView([focusPoint.lat, focusPoint.lon], 13);
    }, [focusPoint])

    /***************************
     *********** ICON **********
     ***************************/



    // // Custom icon
    // const customIcon = L.icon({
    //         iconUrl: markerImg,
    //         iconSize: [25, 41],
    //         iconAnchor: [12, 41],
    //     });

    const customIcon = (num) => {
        return new DivIcon({
                className: 'custom-icon',
                iconAnchor: [10, 26],
                iconSize: [26, 26],
                html: `<img src="${markerImg}" alt="Pin Icon" class="marker-icon" /><div class="marker-number">${num}</div>`
            });
        }

    // const customIcon = new DivIcon({
    //     className: 'custom-icon',
    //     iconAnchor: [10, 26],
    //     iconSize: [26, 26],
    //     html: `<img src="${markerImg}" alt="Pin Icon" class="marker-icon" /><div class="marker-number">1</div>`
    // });

    useEffect(()=>{
        if(mapRef.current) {
            var div = L.DomUtil.get('overlay'); // this must be an ID, not class!
            L.DomEvent.on(div, 'mousewheel', L.DomEvent.stopPropagation);
            L.DomEvent.on(div, 'click', L.DomEvent.stopPropagation);
        }
    },[])

    const randomColors = [
        "FireBrick",
        "DarkOrange",
        "LimeGreen",
        "MediumSlateBlue",
        "IndianRed",
        "DarkTurquoise",
        "Gold",
        "MediumVioletRed",
        "DarkOliveGreen",
        "RoyalBlue",
        "SeaGreen",
        "SlateGray",
        "Peru",
        "DarkGoldenRod",
        "MediumPurple",
        "MediumSeaGreen",
        "Tomato",
        "CornflowerBlue",
        "OrangeRed",
        "SteelBlue",
        "SaddleBrown",
        "MediumAquamarine",
        "DarkKhaki",
        "Sienna",
        "DarkCyan",
        "MediumOrchid",
        "LightSeaGreen",
        "Chocolate",
        "OliveDrab",
        "CadetBlue"
    ];


    return(
        <div className={'map-container'}>
            <MapContainer
                ref = {mapRef}
                // center={[36.90812, 30.69556]}
                center={[41.014777094657866, 28.959669003355604]}
                zoom={12}
                scrollWheelZoom={true}
                placeholder={<MapPlaceholder />}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <>
                    {areas?.map(ar => {
                        if(ar.id === focusArea?.id && editMode) return;
                        if(ar.id === focusArea?.id) return <AreaPolygon label={focusArea.name.replace(/ /g, "\u00A0")} positions={focusArea.polygon} color={focusArea.color?focusArea.color:'blue'} fillColor={focusArea.color?focusArea.color:'blue'} fill={true}/>;
                        return <AreaPolygon label={ar.name.replace(/ /g, "\u00A0")} positions={ar.polygon} color={ar.color?ar.color:'blue'} fillColor={ar.color?ar.color:'blue'} fill={true}/>;
                    })
                    }
                </>
                {focusCustomer?.location?(
                    <>
                    <FocusMap lat={focusCustomer.location.lat} lng={focusCustomer.location.lon}></FocusMap>
                    <Circle center={[focusCustomer.location.lat, focusCustomer.location.lon]} radius={10} color="blue"/>
                    </>
                ):null}
                {focusRoute?.map((item, index)=>{
                   return (
                       <Marker position={[item.location.lat, item.location.lon]} icon={customIcon(index)}>
                        <Popup>
                            A popup for the custom marker.
                        </Popup>
                    </Marker>)
                    })
                }
                {customers?
                customers.map(item=>{
                    if(item.location) return <Circle center={[item.location.lat, item.location.lon]} radius={10} color="blue"/>
                }):null
                }
                <div>hello {dispatchResultsInfo?.id}</div>
                <>
                {

                    focusRoute?null:dispatchResultsInfo?.vehiclesDispatches.map((item, index_d)=>{
                        console.log('>>>', item);
                        var color_mark = Math.random().toString(16).substr(-6);
                        return (
                            <>
                                {item.departures.map((item, index) => {
                                    return <>
                                        {item.visitNodes.map((item, index) => {
                                            return (
                                                <CircleMarker center={item.location} color={randomColors[index_d]} >
                                                    <Popup>
                                                        A popup for the custom marker.
                                                    </Popup>
                                                </CircleMarker>
                                            )
                                        })}
                                    </>
                                })
                                }
                            </>)

                    })
                }
                </>
                <FeatureGroup ref={featureGroupRef}>
                </FeatureGroup>
            </MapContainer>
        </div>
    );
}

export default Map;
