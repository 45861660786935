import logo from './logo.svg';
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Map from "./Components/Map";
import Screen from "./Components/Screen";
import Menue from "./Components/MainMenue";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Areas from "./Pages/Areas/Areas";
import {MapProvider} from "./Components/MapProvider";
import {DataProvider} from "./Components/DataProvider";
import Vehicles from "./Pages/Vehicles/Vehicles";
import Customers from "./Pages/Customers/Customers";
import Orders from "./Pages/Orders/Orders";
import Results from "./Pages/Results/Results";
import RoutingMap from "./Test";
import MapComponent from "./Test2";
import WebSocket from "./Components/WebSocket";
import {AuthProvider} from "./Components/AuthProvider";

function App() {
  return (
      // <div className="App">
      //     <RoutingMap />
      // </div>
      <AuthProvider>
          <DataProvider>
              <MapProvider>
                  <WebSocket />
                <div className="App">
                    <Map></Map>
                    <Screen />
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Areas />} />
                            <Route  index path="areas" element={<Areas />} />
                            <Route  index path="vehicles" element={<Vehicles />} />
                            <Route  index path="customers" element={<Customers />} />
                            <Route  index path="orders" element={<Orders />} />
                            <Route  index path="results" element={<Results />} />
                            <Route  index path="results" element={<Results />} />
                            {/*<Route path="workers/add" element={<AddWorkerPage />} />*/}
                            {/*<Route path="workers/worker/:worker_id" element={<Worker />} />*/}
                            {/*<Route path="groups" element={<GroupsPage />} />*/}
                            {/*<Route path="groups/add" element={<AddGroupPage />} />*/}
                        </Routes>
                        <Menue />
                    </BrowserRouter>
                    <ToastContainer />
                </div>
              </MapProvider>
          </DataProvider>
      </AuthProvider>
  );
}

export default App;
