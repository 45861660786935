import './results.css';
import {useMap} from "../../Components/MapProvider";
import {useEffect, useRef, useState} from "react";
import {useData} from "../../Components/DataProvider";
import RedCircleIcon from "../../Components/Icons/RedCircleIcon";
import Collapsible from "react-collapsible";
import DownloadButton from "../../Components/DownloadButton";
import eyeImg from '../../assets/eye.png'
export const ResultList = ({onItemFocus, newResultRequest, wide=false, addbutton = true}) =>{
    const {dispatchResults} = useData();
    const ResultListItem = ({item, onFocus}) =>{
        return (
            <div className='item' onClick={()=>{
                onFocus(item);
            }}>
                {item.date}
            </div>
        );
    }

    return (
        <div className='result-list-holder'>
            <div className='result-list-content' style={wide?{width: '100%'}:null}>
                <div className='result-scroll-holder'>
                    <div className='result-list'>
                        {
                            dispatchResults.map(item => {
                                return <ResultListItem item={item} onFocus={(i)=>{onItemFocus(i)}} />
                            })
                        }
                    </div>
                </div>
                {addbutton?
                <div className={'new-result'}>
                    <button className={'add-button'} onClick={()=>{newResultRequest()}}>Add</button>
                </div>:null
                }
            </div>

        </div>
    );
}

export const ResultListInfo = ({focusResult, focusResultInfo, wide=false}) =>{
    const {setFocusPoint} = useMap();
    const {setFocusRoute , getReportDownloadUrl} = useData();

    const VehicleDispatchInfo = ({item})=>{
        return (
            <div className='vehicle-dispatch' onClick={()=>{

            }}>

            <div>{item.vehicle.plate}</div>
            <div>{item.travelDistance.toFixed(2)} km</div>
            <div>{item.capacityOverflow.toFixed(2)} kg</div>
            <div>{item.workingHoursOverflow}</div>
        </div>
        );
    }


    const VehicleDepartureInfoHeader = ({item})=>{
        return (
            <div className='vehicle-departure'>
                <div>Departure {item.departureSequence}</div>
                <div className={'image-holder'} onClick={(e)=>{
                    setFocusRoute([...item.visitNodes]);
                    // e.stopPropagation();
                    // e.nativeEvent.stopImmediatePropagation();
                }}>
                    <img src={eyeImg} />
                </div>
            </div>
        );
    }

    const VehicleDepartureInfoVisit = ({item, index})=>{
        return (
            <div>
            <div className='item-2' title={item.customerName} onClick={()=>{
                setFocusPoint(item.location);
            }}>
                <div>{index}</div>
                <div>{item.customerId}</div>
                {/*<div>{item.customerName}</div>*/}
                <div className='item-2'>
                    <div style={{minWidth: '9ch'}}>{item.capacity.toFixed(2)}</div>
                    <div style={{minWidth: '8ch'}}>{item.eta}</div>
                    <div>-</div>
                    <div style={{minWidth: '8ch'}}>{item.etd}</div>
                </div>
            </div>
            </div>
        );
    }


    const VehicleDepartureInfo = ({item})=>{
            return (
                <Collapsible open = {true} trigger={
                    <VehicleDepartureInfoHeader item={item}/>
                }>
                    {item.visitNodes.map((visit, index)=>{
                        return <VehicleDepartureInfoVisit index={index} item={visit} ></VehicleDepartureInfoVisit>
                    })}

                </Collapsible>
            );
        }



    const ResultListItem = ({item, onFocus}) =>{
        return (
            <Collapsible trigger={<VehicleDispatchInfo item={item} />}>
                {item.departures.map(departure=>{
                    return <VehicleDepartureInfo item={departure} ></VehicleDepartureInfo>
                })}

            </Collapsible>

        );
    }

    return (
        <>
            {focusResultInfo?
                (
                    <div className='result-list-holder' style={wide?{width: '50%'}: {width: '50%'}}>
                        <div className='result-list-content' style={{width: '100%'}}>
                            <div className='result-scroll-holder'>
                                <div className='result-list'>
                                    {
                                        focusResultInfo?.vehiclesDispatches.map(item => {
                                            return <ResultListItem item={item} onFocus={(i)=>{}} />
                                        })
                                    }
                                </div>
                            </div>
                            <DownloadButton url={getReportDownloadUrl(focusResult?.id)}>Download Report</DownloadButton>
                        </div>

                    </div>
                ): null
            }
        </>
    );
}
