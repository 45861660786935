import './vehicles.css';
import {useMap} from "../../Components/MapProvider";
import {useEffect, useRef, useState} from "react";
import {useData} from "../../Components/DataProvider";
export const VehicleList = ({onItemFocus, newVehicleRequest, wide=false, addbutton = true}) =>{
    const {vehicles} = useData();
    const VehicleListItem = ({item, onFocus}) =>{
        return (
            <div className='item' onClick={()=>{
                onFocus(item);
            }}>
                {item.plate}
            </div>
        );
    }

    return (
        <div className='vehicle-list-holder'>
            <div className='vehicle-list-content' style={wide?{width: '100%'}:null}>
                <div className='vehicle-scroll-holder'>
                    <div className='vehicle-list'>
                        {
                            vehicles.map(item => {
                                return <VehicleListItem item={item} onFocus={(i)=>{onItemFocus(i)}} />
                            })
                        }
                    </div>
                </div>
                {addbutton?
                <div className={'new-vehicle'}>
                    <button className={'add-button'} onClick={()=>{newVehicleRequest()}}>Add</button>
                </div>:null
                }
            </div>

        </div>
    );
}

export const VehicleInfo = ({commitVehicle}) => {
    const {focusVehicle, setFocusVehicle} = useData();
    const [newTitle, setNewTitle]  = useState('');
    const [newCapacity, setCapacity]  = useState(0);

    // This will update the title if we change focus area if exits.
    // Otherwise, will prepare input for the fresh data.
    useEffect(()=>{
        if(focusVehicle){
            setNewTitle(focusVehicle.plate);
            setCapacity(focusVehicle.capacity);
        }
        else{
            setNewTitle('');
            setCapacity(0);
        }
    }, [focusVehicle])

    // This will control the input change.
    const titleInputChanged = (e) =>{
        let value = e.target.value;
        // Change title in focus area if not null, this will automatically update the newTitle using hook above.
        // Otherwise, change newTitle manually
        if(focusVehicle) setFocusVehicle(prev => ({...prev, plate: value}));
        else setNewTitle(value);
    }

    const capacityInputChanged = (e) =>{
        let value = e.target.value;
        // Change title in focus area if not null, this will automatically update the newTitle using hook above.
        // Otherwise, change newTitle manually
        if(focusVehicle) setFocusVehicle(prev => ({...prev, capacity: value}));
        else setCapacity(value);
    }

    return(
    <div className={'vehicle-info-holder'}>
        <div className='vehicle-info-content'>
            <div className={'vehicle-scroll-holder'}>
                <div className={'vehicle-info'}>
                    <input name={'vehicleTitle'}
                           onInput={(e)=>{titleInputChanged(e)}}
                           className={'vehicle-title'}
                           type={'text'}
                           value={newTitle}
                           placeholder={'vehicle Name'}/>
                        <h4>Capacity</h4>
                            <input name={'vehicleCapacity'}
                                   onInput={(e)=>{capacityInputChanged(e)}}
                                   className={'vehicle-title'}
                                   type={'number'}
                                   value={newCapacity}
                                   placeholder={'Capacity'}/>
                </div>
            </div>
            <div className={'new-vehicle'}>
                <button className={'add-button'} onClick={()=>{commitVehicle(newTitle, newCapacity)}}>{focusVehicle?"Save":"Add"}</button>
            </div>
        </div>
    </div>
    );
}
