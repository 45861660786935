import React from 'react';

const DownloadButton =({url, children}) => {

    return (
        <div>
            <a className={'download-a-button'} href={url}>{children}</a>
        </div>
    );
}

export default DownloadButton;
