// MapContext.js
import React, {createContext, useState, useContext, useEffect} from 'react';
import AuthScreen from "../Pages/Auth/AuthPage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const request_url = "https://dispatchapi.rotaoptimizasyonu.com/api/auth/login/";
    const status_url = "https://dispatchapi.rotaoptimizasyonu.com/api/auth/status/";
    const [authError, setAuthError] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // useEffect(()=>{
    //     alert(isAuthenticated)
    // }, [isAuthenticated])

    const authentificate = async (email, password) => {
        try {
            const response = await fetch(request_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ email, password }),
            });
            var json = await response.json();
            if (!response.ok) {
                setIsAuthenticated(false);
                throw new setAuthError('Authentication failed');
            }

            // Authentication successful, extract cookies
            // const cookies = response.headers.get('set-cookie');
            // alert(cookies);
            // if (cookies) {
            //     // Store cookies in local storage or cookies
            //     localStorage.setItem('authCookies', cookies);
            // }

            setIsAuthenticated(true);

            // Authentication successful, redirect or perform additional actions
        } catch (error) {
            setIsAuthenticated(false);
            setAuthError('Invalid username or password');
            console.error('Authentication error:', error);
        }
    };


    const check = async () => {
        try {
            const response = await fetch(status_url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            var json = await response.json();
            if (!response.ok) {
                setIsAuthenticated(false);
                throw new setAuthError('Authentication failed');
            }
            // alert(response.ok);
            setIsAuthenticated(true);
            // Authentication successful, redirect or perform additional actions
        } catch (error) {
            setIsAuthenticated(false);
            setAuthError('Invalid username or password');
            console.error('Authentication error:', error);
        }
    }

    useEffect(()=>{
        check()
    },[])

    return (
        <AuthContext.Provider value={{isAuthenticated, authentificate}}>
            {isAuthenticated===false?(
                <div className="App">
                    <AuthScreen authentificate = {(email, password)=>{authentificate(email, password)}}></AuthScreen>
                </div>
                ):children
            }
        </AuthContext.Provider>
    );
};

export const useAuth= () => {
    return useContext(AuthContext);
};
