import './areas.css';
import {useMap} from "../../Components/MapProvider";
import {useEffect, useRef, useState} from "react";
import {useData} from "../../Components/DataProvider";
export const AreaList = ({onItemFocus, newAreaRequest}) =>{
    const {areas} = useData();
    const AreaListItem = ({item, onFocus}) =>{
        return (
            <div className='item' onClick={()=>{
                onFocus(item);
            }}>
                {item.name}
            </div>
        );
    }



    return (
        <div className='area-list-holder'>
            <div className='area-list-content'>
                <div className='area-scroll-holder'>
                    <div className='area-list'>
                        {
                            areas.map(item => {
                                return <AreaListItem item={item} onFocus={(i)=>{onItemFocus(i)}} />
                            })
                        }
                    </div>
                </div>
                <div className={'new-area'}>
                    <button className={'add-button'} onClick={()=>{newAreaRequest()}}>Add</button>
                </div>
            </div>

        </div>
    );
}

export const AreaInfo = ({isEditMode, toggleEditMode, clearBorders, commitArea, selectVehicles, toggleSelectVehicles, removeVehicle}) => {
    // Load FocusArea To Display Information
    const {focusArea, setFocusArea, clearAreaPolygon, setAreas} = useData();
    // This will keep the title for the cases if title was edited
    const [newTitle, setNewTitle]  = useState('');

    // This will update the title if we change focus area if exits.
    // Otherwise, will prepare input for the fresh data.
    useEffect(()=>{
        if(focusArea) setNewTitle(focusArea.name);
        else setNewTitle('');
    }, [focusArea])

    // This will control the input change.
    const titleInputChanged = (e) =>{
        let value = e.target.value
        // Change title in focus area if not null, this will automatically update the newTitle using hook above.
        // Otherwise, change newTitle manually
        if(focusArea) setFocusArea(prev => ({...prev, name: value}));
        else setNewTitle(value);
    }

    const AreaVehicleItem = ({item, onFocus}) =>{
        return (
            <div className='item' title='Click to remove'  onClick={()=>{
                onFocus(item);
            }}>
                {item.plate}
            </div>
        );
    }

    return(
    <div className={'area-info-holder'}>
        <div className='area-info-content'>
            <div className={'area-scroll-holder'}>
                <div className={'area-info'}>
                    <input name={'areaTitle'}
                           onInput={(e)=>{titleInputChanged(e)}}
                           className={'area-title'}
                           type={'text'}
                           value={newTitle}
                           placeholder={'Area Name'}/>
                    {focusArea?    <>
                        <h4>Area Broders</h4>
                        {/*If in edit mode alow clearing*/}
                        {
                            (focusArea&&focusArea.polygon.length>0)?
                                (isEditMode?null:<button className={'edit-area-bounds-button'} style={{marginBottom: 10}} onClick={clearBorders}>Clear</button>): null
                        }

                        <button className={'edit-area-bounds-button'} onClick={toggleEditMode}>{isEditMode?"Save":"Edit"}</button>
                        <h4>Vehicles</h4>
                            <div className='plates'>
                                {focusArea.vehicles.map(item => {
                                        return <AreaVehicleItem item={item} onFocus={(i)=>{removeVehicle(i)}} />
                                    })
                                }
                            </div>
                        <button className={'edit-area-bounds-button'} onClick={()=>{
                            toggleSelectVehicles();
                        }}>{selectVehicles?"Hide":"Add"}</button>
                        </>
                        :null}
                </div>
            </div>
            <div className={'new-area'}>
                <button className={'add-button'} onClick={()=>{commitArea(newTitle)}}>{focusArea?"Save":"Add"}</button>
            </div>
        </div>
    </div>
    );
}
