// MapContext.js
import React, {createContext, useState, useContext, useEffect} from 'react';
import {useData} from "./DataProvider";

const MapContext = createContext();

export const MapProvider = ({ children }) => {

    /*
    ##########
    PolyLines
    ##########
     */
    const [polygons, setStatePolygons] = useState([]);
    const [focusArea, setFocusArea] = useState(null);
    const [editMode, setEdit] = useState(false);

    const [focusPoint, setFocusPoint] = useState(null);

    const toggleEditMode = () =>{
        setEdit(!editMode);
    }

    const setPolygons = (polygon) =>{
        setStatePolygons([polygon]);
    }
    return (
        <MapContext.Provider value={{
            polygons,
            setPolygons,
            editMode,
            toggleEditMode,
            //Focus Area For Renders
            focusArea, setFocusArea,
            focusPoint, setFocusPoint
        }}>
            {children}
        </MapContext.Provider>
    );
};

export const useMap = () => {
    return useContext(MapContext);
};
