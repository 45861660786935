// MapContext.js
import React, {createContext, useState, useContext, useEffect} from 'react';

const DataContext = createContext();

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const DataProvider = ({ children }) => {


    const request_url = "https://dispatchapi.rotaoptimizasyonu.com/api/v1"

    const getReportDownloadUrl = (id) => {
        return `${request_url}/results/${id}/excel/`
    }

    const loadAreas = () => {
        var requestOptions = {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
        };

        fetch(`${request_url}/areas/`, requestOptions)
            .then(response => {
                if(response.ok) return response.json();
                else throw new Error('Auth Needed');
            })
            .then(result => {
                setAreas(result);
                console.log(">>>",result);
            })
            .catch(error => console.log('error', error));
    }

    const serverAddArea = (area_name) =>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-CSRFToken", getCookie('csrftoken'));

        var raw = JSON.stringify({name: area_name, color:'blue'});

        var requestOptions = {
            method: 'POST',
            credentials: 'include',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${request_url}/areas/`, requestOptions)
            .then(response =>
            {
                if(response.ok) return response.json();
                else throw new Error('Auth Needed');
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error)).finally(()=>{
            loadAreas();
        });
    }

    const serverEditArea = (area) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-CSRFToken", getCookie('csrftoken'));
        console.log("P", area);
        var raw = JSON.stringify(area);

        var requestOptions = {
            method: 'PATCH',
            credentials: 'include',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${request_url}/area/${area.id}/`, requestOptions)
            .then(response => {
                if(response.ok) return response.json();
                else throw new Error('Auth Needed');
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error)).finally(()=>{
                loadAreas();
        });
    }

    const [focusArea, setFocusArea] = useState(null);
    const [areas, setAreas] = useState([]);

    useEffect(()=>{
        console.log(focusArea)
    }, [focusArea])
    //This will update the focus area on the main data holder.
    // const updatePolygon = (area_id, newCoordinates) => {
    //     if (focusArea===null) return;
    //     setAreas(prevPolygons =>
    //         prevPolygons.map(polygon =>
    //             polygon.id === area_id ? { ...polygon, polygon: Object(newCoordinates)} : polygon
    //         )
    //     );
    // }


    //Update polygons on the focused area. (not affecting the main data holder)
    const updatePolygon = (newCoordinates) => {
        if(focusArea){
            setFocusArea(prev => ({...prev, polygon: newCoordinates}));
        }
    }

    const clearAreaPolygon = () => {
        if (focusArea===null) return;
        setFocusArea(prev => ({...prev, polygon: []}));
    }


    /*
    #########################################
    Vehicles
    #########################################
     */

    const [focusVehicle, setFocusVehicle] = useState(null);
    const [vehicles, setVehicles] = useState([]);

    const loadVehicles = () => {
        var requestOptions = {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
        };

        fetch(`${request_url}/vehicles/`, requestOptions)
            .then(response => {
                if(response.ok) return response.json();
                else throw new Error('Auth Needed');
            })
            .then(result => {
                setVehicles(result);
                console.log(result);
            })
            .catch(error => console.log('error', error));
    }

    const serverAddVehicle= (vehicle_plate, vehicle_capacity) =>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-CSRFToken", getCookie('csrftoken'));

        var raw = JSON.stringify({plate: vehicle_plate, capacity: vehicle_capacity});

        var requestOptions = {
            method: 'POST',
            credentials: 'include',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${request_url}/vehicles/`, requestOptions)
            .then(response => {
                if(response.ok) return response.json();
                else throw new Error('Auth Needed');
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error)).finally(()=>{
            loadVehicles();
        });
    }

    const serverEditVehicle = (vehicle) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(vehicle);

        var requestOptions = {
            method: 'PATCH',
            credentials: 'include',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${request_url}/vehicle/${vehicle.id}/`, requestOptions)
            .then(response => {
                if(response.ok) return response.json();
                else throw new Error('Auth Needed');
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error)).finally(()=>{
            loadVehicles();
        });
    }


    /*
    #########################################
    Customers
    #########################################
     */

    const [focusCustomer, setFocusCustomer] = useState(null);
    const [customers, setCustomers] = useState([]);

    const loadCustomers = async (nextPage=1) => {
        var requestOptions = {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
        };


        // return await fetch(`${request_url}/customers/?withlocations=1&page=${nextPage}`, requestOptions)
        return await fetch(`${request_url}/customers/?page=${nextPage}`, requestOptions)
            .then(response => {
                if(response.ok) return response.json();
                else throw new Error('Auth Needed');
            })
            .then(result => {
                if(result?.results) {
                    if (nextPage == 1) setCustomers(result?.results);
                    else setCustomers(prevState => ([...prevState, ...result.results]));
                    console.log(result);
                    console.log(result.next);
                }
                return result?.next;
            })
            .catch(error => {
                console.log('error', error);
                return null;
            });
    }

    const serverAddCustomer= (customer_plate, customer_capacity) =>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-CSRFToken", getCookie('csrftoken'));

        var raw = JSON.stringify({plate: customer_plate, capacity: customer_capacity});

        var requestOptions = {
            method: 'POST',
            credentials: 'include',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${request_url}/customers/`, requestOptions)
            .then(response => {
                if(response.ok) return response.json();
                else throw new Error('Auth Needed');
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error)).finally(()=>{
            loadCustomers();
        });
    }

    const serverEditCustomer = (customer) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json; charset=utf-8");
        var raw = JSON.stringify(customer);

        var requestOptions = {
            method: 'PATCH',
            credentials: 'include',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${request_url}/customer/${customer.id}/`, requestOptions)
            .then(response => {
                response.json();
            })
            .then(result => {
                const updatedItems = customers.map(item => {
                    // If the current item has the same ID as the one we're looking for, replace it with the new item
                    if (item.id === result.id) {
                        return result;
                    }
                    // Otherwise, return the current item unchanged
                    return item;
                });

                // Update the state with the new array
                setCustomers(updatedItems);
            })
            .catch(error => console.log('error', error)).finally(()=>{
            loadCustomers();
        });
    }


    /*
    #########################################
    Orders
    #########################################
     */

    const [orders, setOrders] = useState([]);

    const loadOrders= async () => {

        var requestOptions = {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
        };

        // return await fetch(`${request_url}/customers/?withlocations=1&page=${nextPage}`, requestOptions)
        return await fetch(`${request_url}/orders/`, requestOptions)
            .then(response => {
                if(response.ok) return response.json();
                else throw new Error('Auth Needed');
            })
            .then(result => {
                setOrders(prevState => (result));
                return result;
            })
            .catch(error => {
                console.log('error', error);
                return null;
            });
    }

    const loadOrder= async (order) => {
        var requestOptions = {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
        };


        // return await fetch(`${request_url}/customers/?withlocations=1&page=${nextPage}`, requestOptions)
        return await fetch(`${request_url}/orders/${order.id}/`, requestOptions)
            .then(response => {
                if(response.ok) return response.json();
                else throw new Error('Auth Needed');
            })
            .then(result => {
                return result;
            })
            .catch(error => {
                console.log('error', error);
                return null;
            });
    }
    const requestDispatch= async (order) => {
        var myHeaders = new Headers();
        myHeaders.append("X-CSRFToken", getCookie('csrftoken'));


        var requestOptions = {
            method: 'POST',
            credentials: 'include',
            headers: myHeaders,
            redirect: 'follow'
        };


        // return await fetch(`${request_url}/customers/?withlocations=1&page=${nextPage}`, requestOptions)
        return await fetch(`${request_url}/orders/${order.id}/`, requestOptions)
            .then(response => {
                if(response.ok) return response.json();
                else throw new Error('Auth Needed');
            })
            .then(result => {
                return result;
            })
            .catch(error => {
                console.log('error', error);
                return null;
            });
    }


    const uploadOrderFile = (file) => {
        const currentDate = new Date();

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(currentDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;


        const formdata = new FormData();
        formdata.append("file", file, file.name);
        formdata.append("date", formattedDate);

        var myHeaders = new Headers();
        myHeaders.append("X-CSRFToken", getCookie('csrftoken'));


        const requestOptions = {
            method: "POST",
            credentials: 'include',
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        fetch(`${request_url}/orders/`, requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.error(error));
    }


    /*
    #########################################
    Dispatch Results
    #########################################
     */

    const [dispatchResults, setDispatchResults] = useState([]);
    const [dispatchResultsInfo, setDispatchResultsInfo] = useState(null);
    const [focusRoute, setFocusRoute] = useState(null);
    const loadDispatchResults= async () => {
        var requestOptions = {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
        };

        // return await fetch(`${request_url}/customers/?withlocations=1&page=${nextPage}`, requestOptions)
        return await fetch(`${request_url}/results/`, requestOptions)
            .then(response => {
                if(response.ok) return response.json();
                else throw new Error('Auth Needed');
            })
            .then(result => {
                setDispatchResults(prevState => (result));
                return result;
            })
            .catch(error => {
                console.log('error', error);
                return null;
            });
    }

    const loadDispatchResult= async (order) => {
        var requestOptions = {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
        };

        // return await fetch(`${request_url}/customers/?withlocations=1&page=${nextPage}`, requestOptions)
        return await fetch(`${request_url}/results/${order.id}/`, requestOptions)
            .then(response => {
                if(response.ok) return response.json();
                else throw new Error('Auth Needed');
            })
            .then(result => {
                return result;
            })
            .catch(error => {
                console.log('error', error);
                return null;
            });
    }


    const clear = () =>{
        setFocusArea(null);
        setFocusVehicle(null);
        setFocusCustomer(null);
        setAreas([]);
        setCustomers([]);
        setVehicles([])
        setDispatchResults([]);
    }

    return (
        <DataContext.Provider value={{
            clear,
            areas,
            setAreas,
            //Focus Area
            focusArea,
            setFocusArea,
            clearAreaPolygon,
            updatePolygon,
            //URL REQUESTS
            loadAreas,
            serverAddArea,
            serverEditArea,
            //Vehicles
            vehicles,
            setVehicles,
            focusVehicle,
            setFocusVehicle,
            loadVehicles,
            serverAddVehicle,
            serverEditVehicle,
            //Customers
            customers,
            setCustomers,
            focusCustomer,
            setFocusCustomer,
            loadCustomers,
            serverAddCustomer,
            serverEditCustomer,
            //Orders,
            orders,
            loadOrders,
            loadOrder,
            uploadOrderFile,
            requestDispatch,
            //
            dispatchResults,
            loadDispatchResults,
            loadDispatchResult,
            dispatchResultsInfo,
            setDispatchResultsInfo,
            focusRoute,
            setFocusRoute,
            getReportDownloadUrl,
        }}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => {
    return useContext(DataContext);
};
