import Screen from "../../Components/Screen";
import './areas.css'
import {AreaInfo, AreaList} from './AreaComponents'
import {useEffect, useState} from "react";
import {useMap} from "../../Components/MapProvider";
import {useData} from "../../Components/DataProvider";
import {VehicleList} from "../Vehicles/VehicleComponents";
import vehicles from "../Vehicles/Vehicles";
const Areas = () =>{
    // const [focusArea, setFocusArea] = useState(null)
    const {editMode, toggleEditMode,} = useMap();
    const {focusArea, setFocusArea, clearAreaPolygon, setAreas, loadAreas, serverAddArea, serverEditArea, loadVehicles} = useData();
    const onAreaFocus = (area) => {
        if(area) {
            if (editMode) toggleEditMode();
            setFocusArea(area);
        }
    }

    const createNewArea = () => {
       setFocusArea(null);
    }

    useEffect(()=>{
        console.log("Called")
        loadAreas()
    }, [])

    const commitArea = (title) => {
        if(focusArea){
            console.log('>>>', Object(focusArea));
            serverEditArea(Object(focusArea));
        }
        else{
            serverAddArea(title);
        }
    }

    const [showVehicles, setShowVehicles] = useState(false);
    const selectVehicles = () => {
        if(!showVehicles) loadVehicles();
        setShowVehicles(prevState => (!prevState));
    }

    const vehicleAdded = (vehicle) => {
        if(focusArea===null) return;
        var oldlist = focusArea.vehicles;
        console.log('old', oldlist);
        //Check if vehicle is already there
        const itemExists = oldlist.some(item => item.id === vehicle.id);
        if(itemExists) return;

        const extendedArray = [...oldlist, Object(vehicle)];
        setFocusArea(prev=>({...prev, vehicles: extendedArray}));

    }

    const removeVehicle = (vehicle) => {
        var oldlist = focusArea.vehicles;
        const filteredItems = oldlist.filter(item => item.id !== vehicle.id);
        setFocusArea(prev=>({...prev, vehicles: filteredItems}));
    }

    return (
        <Screen colLeft={
            <AreaInfo selectVehicles={showVehicles} toggleSelectVehicles={selectVehicles} isEditMode={editMode} toggleEditMode={toggleEditMode} clearBorders={clearAreaPolygon} commitArea={(title)=>{commitArea(title)}} removeVehicle={removeVehicle} />
        }
                colCenter={
            showVehicles?
                <VehicleList onItemFocus={(vehicle) => {vehicleAdded(vehicle)}} wide={true} addbutton={false}/>:null
        }
                colRight={
            <AreaList onItemFocus={(area)=> {onAreaFocus(area)}} newAreaRequest = {()=> {createNewArea()}} />
        }
        />
    );
}

export default Areas;
