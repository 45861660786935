import Screen from "../../Components/Screen";
import './vehicles.css'
import {useEffect, useState} from "react";
import {useData} from "../../Components/DataProvider";
import {VehicleInfo, VehicleList} from "./VehicleComponents";
const Vehicles = () =>{
    const {vehicles,
        setVehicles,
        focusVehicle,
        setFocusVehicle,
        loadVehicles,
        serverAddVehicle,
        serverEditVehicle,
    } = useData();
    const onVehicleFocus = (vehicle) => {
        if(vehicle) {
            setFocusVehicle(vehicle);
        }
    }

    const createNewVehicle = () => {
        setFocusVehicle(null);
    }

    useEffect(()=>{
        loadVehicles()
    }, [])

    const commitVehicle = (title, capacity) => {
        if(focusVehicle){
            serverEditVehicle(Object(focusVehicle));
        }
        else{
            serverAddVehicle(title, capacity);
        }
    }

    return (
        <Screen colLeft={<VehicleInfo commitVehicle={(title, capacity)=>{commitVehicle(title, capacity)}}/>}
                colRight={<VehicleList onItemFocus={(area)=> {onVehicleFocus(area)}} newVehicleRequest = {()=> {createNewVehicle()}} />}
        />
    );
}

export default Vehicles;
