import Screen from "../../Components/Screen";
import './orders.css'
import {useEffect, useState} from "react";
import {useData} from "../../Components/DataProvider";
import {OrderList, OrderListInfo} from "./OrderComponents";
const Orders = () =>{
    const {
        orders,
        loadOrders,
        loadOrder,
    } = useData();

    //since we won't save or change it, we can keep it within the page context
    const [focusOrderInfo, setFocusOrderInfo] = useState(null)
    const [focusOrder, setFocusOrder] = useState(null)


    const [isOrderLoading, setIsOrderLoading] = useState(false)

    const onOrderFocus = async (order) => {
        if(order) {
            setFocusOrderInfo(null);
            setFocusOrder(null)
            setIsOrderLoading(true);
            var orderinfo = await loadOrder(order);
            setFocusOrderInfo(orderinfo);
            setIsOrderLoading(false);
            setFocusOrder(order);
        }
    }

    useEffect(()=>{
        loadOrders()
    }, [])


    return (
        <Screen layout={2} colLeft={<OrderListInfo focusOrder ={focusOrder} focusOrderInfo={focusOrderInfo} wide={true} isOrderLoading={isOrderLoading}/>}
                colRight={<OrderList onItemFocus={(area)=> {onOrderFocus(area)}} newOrderRequest = {()=> {}} />}
        />
    );
}

export default Orders;
