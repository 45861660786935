import Screen from "../../Components/Screen";
import './customers.css'
import {useEffect, useState} from "react";
import {useData} from "../../Components/DataProvider";
import {CustomerInfo, CustomerList} from "./CustomerComponents";
const Cusotmers = () =>{
    const {
        customers,
        setCustomers,
        focusCustomer,
        setFocusCustomer,
        loadCustomers,
        serverAddCustomer,
        serverEditCustomer,
    } = useData();
    const onCustomerFocus = (customer) => {
        if(customer) {
            setFocusCustomer(customer);
        }
    }

    const createNewCustomer = () => {
        setFocusCustomer(null);
    }

    // useEffect(()=>{
    //     loadCustomers()
    // }, [])

    const commitCustomer = () => {
        if(focusCustomer){
            serverEditCustomer(Object(focusCustomer));
        }
        // else{
        //     serverAddCustomer(title, capacity);
        // }
    }

    return (
        <Screen colLeft={<CustomerInfo commitCustomer={()=>{commitCustomer()}}/>}
                colRight={<CustomerList onItemFocus={(area)=> {onCustomerFocus(area)}} newCustomerRequest = {()=> {createNewCustomer()}} />}
        />
    );
}

export default Cusotmers;
