import './orders.css';
import {useMap} from "../../Components/MapProvider";
import {useEffect, useRef, useState} from "react";
import {useData} from "../../Components/DataProvider";
import RedCircleIcon from "../../Components/Icons/RedCircleIcon";
import FileUploadButton from "../../Components/FileUploadButton";
import {upload} from "@testing-library/user-event/dist/upload";
import ActionButton from "../../Components/ActionButton";
export const OrderList = ({onItemFocus, newOrderRequest, wide=false, addbutton = true}) =>{
    const {orders, uploadOrderFile} = useData();
    const OrderListItem = ({item, onFocus}) =>{
        return (
            <div className='item' onClick={()=>{
                onFocus(item);
            }}>
                {item.date}
            </div>
        );
    }

    const uploadFile = (file) => {
        uploadOrderFile(file);
    }

    return (
        <div className='order-list-holder'>
            <div className='order-list-content' style={wide?{width: '100%'}:null}>
                <div className='order-scroll-holder'>
                    <div className='order-list'>
                        {
                            orders.map(item => {
                                return <OrderListItem item={item} onFocus={(i)=>{onItemFocus(i)}} />
                            })
                        }
                    </div>
                </div>
                {addbutton?
                <div className={'new-order'}>
                    <FileUploadButton uploadFile={(file)=>{uploadFile(file)}}>Add</FileUploadButton>
                    {/*<button className={'add-button'} onClick={()=>{newOrderRequest()}}>Add</button>*/}
                </div>:null
                }
            </div>

        </div>
    );
}

export const OrderListInfo = ({ focusOrder, focusOrderInfo, isOrderLoading, wide=false}) =>{
    const {requestDispatch} = useData();
    const OrderListItem = ({item, onFocus}) =>{
        return (
            <div className='item-2' onClick={()=>{
                // onFocus(item);
            }}>
                <div>{item.customerCode}</div>
                <div>{item.customerName}</div>
                <div className={'capacityAndLocationInfo'}>
                    <div>{item.capacity?.toFixed(2)}</div>
                    <RedCircleIcon show={!item.dataOk} />
                </div>
            </div>
        );
    }

    return (
        <>
            {focusOrderInfo?
                (
                    <div className='order-list-holder'>
                        <div className='order-list-content' style={wide?{width: '100%'}:null}>
                            <div className='order-scroll-holder'>
                                <div className='order-list'>
                                    {
                                        focusOrderInfo?.map(item => {
                                            return <OrderListItem item={item} onFocus={(i)=>{}} />
                                        })
                                    }
                                </div>
                            </div>
                            <div className={'new-order'}>
                                <ActionButton action={()=>{requestDispatch(focusOrder)}}>Dispatch</ActionButton>
                            </div>
                        </div>
                    </div>
                ): isOrderLoading?(
                    <div className='order-list-holder'>
                        <div className='order-list-content' style={wide?{width: '100%'}:null}>
                            Loading ...
                        </div>
                    </div>
                ): null
            }
        </>
    );
}
