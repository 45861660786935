import React, { useState } from 'react';

const ActionButton = ({action, children}) => {
    const handleButtonClick = () => {
        action();
    };

    return (
        <div>
            <button className={'add-button'} onClick={handleButtonClick}>{children}</button>
        </div>
    );
};

export default ActionButton;
