import React, { useState } from 'react';
import Hamburger from 'hamburger-react'
const ExpandableMenue = ({children, isExpanded, setIsExpanded}) => {

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: "column",
        }}>
            <div className={'hamburger-container'}>
                <div className={'hamburger'}>
                    <Hamburger toggled={isExpanded} toggle={setIsExpanded}  direction={'right'} size={20}/>
                </div>
            </div>
            <div className={`expanded-content ${isExpanded ? 'expanded' : ''}`}>
                {children}
            </div>
        </div>
    );
};

export default ExpandableMenue;
