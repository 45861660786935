import './screen.css'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import './Map.css'
import Menue from "./MainMenue";
const Screen = ({colLeft, colCenter, colRight, layout=1}) =>{
    return(
        <>
            {layout==1?
                (<div id='overlay' className={'fill-screen work-area'}>
                    <div className={'work-column'}>{colLeft}</div>
                    <div className={'work-column'}>{colCenter}</div>
                    <div className={'work-column'}>{colRight}</div>
                </div>):
                layout==2?
                    (<div id='overlay' className={'fill-screen work-area'}>
                        <div className={'work-column'} style={{flexGrow: 2, paddingLeft: 20, paddingRight: 0}}>{colLeft}</div>
                        <div className={'work-column'} >{colRight}</div>
                    </div>): null
            }
        </>
    );
}

export default Screen;

